<template>
  <div class="layout-div">
    <iframe
      class="customHeader_iframe"
      scrolling="no"
      src="https://zwdt.sh.gov.cn/zwdtSW/dphead/head/head.jsp?name=SH00HP"
    ></iframe>
    <div class="customContent">
      <div class="sys-title">{{ $messageConfig.systemTitle }}</div>
      <router-view></router-view>
    </div>
    <iframe
      class="customFooter_iframe"
      scrolling="no"
      src="https://zwdt.sh.gov.cn/zwdtSW/dphead/foot/foot.html"
    ></iframe>
  </div>
</template>

<script>
export default {
  methods: {
    //返回按钮指定到首页去
    goBack() {
      this.$storage.DELETE_ActiveChildId();
      this.$router.push({ path: "/" });
    },
  },
  //页面销毁时，取消监听
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },
  mounted() {
    //监听浏览器返回
    if (window.history) {
      history.pushState(null, "", document.URL); //这里有没有都无所谓，最好是有以防万一
      window.addEventListener("popstate", this.goBack, false); // 回退时执行goback方法
    }
  },
};
</script>
<style lang="css" scoped>
.layout-div {
  height: 100vh;
  overflow-y: auto;
}

.layout-div .sys-title {
  height: 12vh;
  color: white;
  background-image: url("../../assets/image/top-tab.png");
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 3rem;
  font-weight: bold;
  padding-top: 4.8rem;
  text-align: center;
}
</style>